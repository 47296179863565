












import { defineComponent, computed } from '@vue/composition-api';

export default defineComponent({
  props: {
    dataValue: {
      required: true,
      type: Number,
    },
    reverse: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const setValue = computed(() => props.dataValue || 0);
    return {
      setValue,
    };
  },
});
